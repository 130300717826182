import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PageContextPropsType } from '../../../i18n/i18n'
import LayoutSimpleFooter from '../../components/LayoutSimpleFooter'
import SEO from '../../components/SEO'
import FormEbook from '../../components/FormEbook'
import classNames from 'classnames/bind'
import * as EbookPageStyles from '../../styles/EbookPage.css'

const cx = classNames.bind(EbookPageStyles)

export default function EbookPage({ pageContext }: PageContextPropsType) {
  const reCaptchaKey = process.env.GATSBY_RECAPTCHA_CLIENT_SECRET_KEY || ''
  return (
    <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
      <LayoutSimpleFooter pageContext={pageContext}>
        <SEO
          title="実践事例つき！ サイト改善鉄板施策15選 | KARTE Blocks"
          description="KARTE Blocksを活用したサイト改善に関する鉄板施策事例をまとめた資料をダウンロードいただけます。"
          path="/ebook/case-2024/"
        />

        <main className={cx('Outer')}>
          <div className={cx('Container')}>
            <section className={cx('Content')}>
              <h1 className={cx('Headline')}>実践事例つき！<br/>サイト改善鉄板施策15選</h1>

              <StaticImage src="../../images/ebook/2024case-thumbnail.png" alt="資料の表紙" />

              <section className={cx('Details')}>
                <h2 className={cx('Details_Headline')}>資料の内容</h2>
                <p className={cx('Details_Summary')}>
                本資料では、実際に成果の上がったサイト改善事例のうち、特に鉄板施策と言える15個の事例をご紹介しています。
                </p>
                <ol className={cx('Details_Outline')}>
                  <li className={cx('Details_Item')}>サイト改善 鉄板事例15選</li>
                  <li className={cx('Details_Item')}>KARTE Blocksについて</li>
                </ol>
                <div className={cx('Details_List_Wrapper')}>
                  <p className={cx('Details_List_Head')}>こんな方におすすめ！</p>
                  <ul className={cx('Details_List_Items')}>
                    <li className={cx('Details_List_Item')}>サイト改善を進めたいが、アイデア不足を感じる方</li>
                    <li className={cx('Details_List_Item')}>他社で成果の上がったサイト改善の実践事例を知りたい方</li>
                    <li className={cx('Details_List_Item')}>サイト改善をこれから始められる方</li>
                  </ul>
                </div>
              </section>
            </section>

            <FormEbook actionUrl="https://deal.karte.io/l/819613/2024-06-24/f88mb" actionName="blockskarteio/ebook/2024case/" />
          </div>
        </main>
      </LayoutSimpleFooter>
    </GoogleReCaptchaProvider>
  )
}
